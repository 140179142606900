import $ from 'jquery';

// Appear on Load Effect - More code in CSS file

$(window).on('load', function () {
  var appear = document.getElementById('appear-load-1');
  $('.appear-load-1').addClass('visible');
});

$(window).on('load', function () {
  var appear = document.getElementById('appear-load-2');
  $('.appear-load-2').addClass('visible');
});

$(window).on('load', function () {
  var appear = document.getElementById('appear-load-3');
  $('.appear-load-3').addClass('visible');
});

$(window).on('load', function () {
  var appear = document.getElementById('appear-load-4');
  $('.appear-load-4').addClass('visible');
});


//Email Split
function myEmail() {
    var email = "info@rvmholdings.com.mt";
    var email_array = email.split(',');
    var new_string = email_array.join('-');
    document.write(new_string);
    document.getElementById("email").innerHTML = res;
}